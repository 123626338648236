import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const generateCertificate = async ({ userName, courseName, loader }) => {
    // Create a temporary div element to render the certificate
    const tempDiv = document.createElement('div');
    tempDiv.style.width = '800px'; // Increased for better clarity
    tempDiv.style.height = '600px';
    tempDiv.style.border = '3px solid #000';
    tempDiv.style.borderRadius = '5px';
    tempDiv.style.padding = '40px';
    tempDiv.style.backgroundColor = '#fdf7e1'; // Light cream background
    tempDiv.style.textAlign = 'center';
    tempDiv.style.position = 'relative';
    tempDiv.style.fontFamily = 'Georgia, serif';
  
    tempDiv.innerHTML = `
      <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px; color: #2d3b50;">
        Certificate of Completion
      </h1>
      <p style="font-size: 20px; margin-bottom: 10px; color: #555;">
        Presented to
      </p>
      <h2 style="font-size: 28px; margin: 10px 0; color: #2d3b50; font-weight: bold;">
        ${userName}
      </h2>
      <p style="font-size: 18px; margin-bottom: 30px; color: #555;">
        for completing the course
      </p>
      <h3 style="font-size: 22px; color: #2d3b50; font-weight: bold;">
        ${courseName}
      </h3>
      <div style="display: flex; justify-content: space-between; align-items: center; margin-top: 50px;">
        <div style="text-align: center;">
          <hr style="width: 150px; margin: 0 auto; border: 1px solid #555;">
          <p style="font-size: 14px; color: #555;">Hannah Morales<br>Director</p>
        </div>
        <div style="text-align: center;">
          <hr style="width: 150px; margin: 0 auto; border: 1px solid #555;">
          <p style="font-size: 14px; color: #555;">Emma Jackson<br>President</p>
        </div>
      </div>
      <div style="position: absolute; bottom: 20px; left: 50%; transform: translateX(-50%);">
        <div style="font-size: 18px; color: #ffd700; font-weight: bold; text-align: center;">
          ★
        </div>
      </div>
    `;
  
    // Append the temporary div to the body
    document.body.appendChild(tempDiv);
  
    try {
      loader(true);
      // Generate the certificate as a PDF
      await html2canvas(tempDiv, { scale: 2 }).then((canvas) => {
        // canvas.scale(2) increases the resolution
        const imgData = canvas.toDataURL('image/png');
        const doc = new jsPDF('p', 'mm', [210, 297]);  // A4 size in mm
        doc.addImage(imgData, 'PNG', 10, 10, 190, 140); // Fit within A4 size
        doc.save(`${courseName}_Certificate.pdf`);
        loader(false);
      });
    } finally {
      loader(false);
      // Remove the temporary div after generating the PDF
      document.body.removeChild(tempDiv);
    }
  };
  
export default generateCertificate;
