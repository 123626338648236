import React, { useState, useRef, useEffect } from 'react'
import InnerPageHeader from '../../../common/InnerPageHeader'
import CourseVideoSection from './CourseVideoSection'
import CourseStatusBox from './CourseStatusBox'
import CourseOverview from './CourseOverview'
import CourseInstructorDetails from './CourseInstructorDetails'
import Skeleton from 'react-loading-skeleton'
import { Icon } from '@iconify/react'
import LearningApis from '../../../queries/learning'
import { Modal, Button } from 'react-bootstrap'
import confetiIMg from '../../../assets/images/rb_17924.png'
import ConfettiExplosion from 'react-confetti-explosion';
import medalImg from '../../../assets/images/medal.png'
import generateCertificate from '../../../utils/generateCertificate'

function SingleView({ learningData, learningNotes, refetchLearningData, courseId, apiLoading, t }) {
    const [taskId, setTaskId] = useState(null)
    const { data: certificateStatus, refetch: getCertificateStatus } = LearningApis.Certificate(courseId)
    const [congratsModal, setcongratsModal] = useState(false)
    const [exploded, setExploded] = useState(false);
    const [downloadLoader, setdownloadLoader] = useState(false)
    const certificateData = LearningApis.CertificateData()
    const handleConfettiClick = () => {
        setExploded(true);
    };
    const handleClose = () => { setcongratsModal(false) }
    const downloadCertificate = async () => {
        const response = await certificateData.mutateAsync({ courseId })
        if (response.status) {
            await generateCertificate(
                {
                    userName: response?.data?.certificateDetails?.username,
                    courseName: response?.data?.certificateDetails?.courseName,
                    loader:setdownloadLoader
                }
            )
        }
    }


    return (
        <>
            <InnerPageHeader
                title='My Learnings'
                subtitile='My Account'
                titlePath='my-learnings'
                subTitlePath='my-account'
                t={t}
            />
            <section class="mylearning_contant_section">

                <div class="container">
                    <div className='d-flex justify-content-between'>
                        <h4 style={{ textTransform: 'capitalize' }}>{apiLoading ? <Skeleton width={80} /> : learningData?.data?.courseDetails?.title}</h4>
                        {
                            certificateStatus?.data?.certificateStatus &&
                            <button className="btn btn-primary mb-2" onClick={downloadCertificate} disabled={downloadLoader}>
                                {downloadLoader ? (
                                    <Icon icon="line-md:downloading-loop" width="24" height="24" />
                                ) : (
                                    <>
                                        <Icon icon="line-md:download-loop" width="20" height="20" /> Certificate
                                    </>
                                )}
                            </button>

                        }
                    </div>

                    <CourseVideoSection
                        learningData={learningData}
                        refetchLearningData={refetchLearningData}
                        courseId={courseId}
                        t={t}
                        apiLoading={apiLoading}
                        setTaskId={setTaskId}
                        getCertificateStatus={getCertificateStatus}
                        setcongratsModal={setcongratsModal}
                    />
                    <CourseStatusBox
                        learningData={learningData}
                        courseId={courseId}
                        t={t}
                        apiLoading={apiLoading}
                    />
                </div>
            </section>
            <section class="course_content_sec">
                <div class="container">
                    <div class="row">
                        <CourseOverview
                            learningData={learningData}
                            learningNotes={learningNotes}
                            t={t}
                            apiLoading={apiLoading}
                            taskId={taskId}
                        />
                        <CourseInstructorDetails learningData={learningData} t={t} apiLoading={apiLoading} />
                    </div>
                </div>
            </section>
            <Modal show={congratsModal} centered dialogClassName="custom-modal" >
                <Modal.Body className="text-center position-relative">

                    <div className="confetti-container position-relative">
                        {/* Confetti Explosion */}
                        {exploded && (
                            <div className="confetti-explosion-container">
                                <ConfettiExplosion
                                    force={0.8} // Adjust explosion strength
                                    duration={3000} // Adjust duration in ms
                                    particleCount={300} // Increase particle count
                                // width={window.innerWidth < 768 ? 200 : 400} // Responsive width
                                />
                            </div>
                        )}

                        {/* Confetti Image */}
                        {!exploded && (
                            <div className="confetti-image-container">
                                <img
                                    src={confetiIMg}
                                    className="confetti-image"
                                    onClick={handleConfettiClick}
                                    alt="Confetti"
                                />
                            </div>
                        )}

                        {/* Text Content */}
                        <div
                            className={`py-4 text-content ${exploded ? 'text-visible' : 'text-faded'
                                }`}
                        >
                            <div>
                                <img src={medalImg} />
                            </div>
                            <h1 className="fw-bold  congrats-heading">Congratulations</h1>
                            <p className="text-muted">You successfully completed this course.</p>
                            <p style={{ color: '#fb5959', fontWeight: 'bold' }}>Download Your Course completed certificate</p>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer><Button onClick={handleClose}>close</Button></Modal.Footer>
            </Modal>



        </>
    )
}

export default SingleView